@use '../settings/' as cms;

.cms-section-title {
    font-size: 30px;
    font-weight: 700;
}

.cms-section {
    padding: 50px 0;
}

.cms-search-category {
    position: relative;
    .cms-container, .cms-card--category {
        padding: 20px;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
    }

    .cms-card--category {
        width: 100%;
    }

    .cms-container {
        padding-top: 100px;
    }
}

.cms-counter {
    // background-image: url(../image/wave-1.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;

    .cms-container {
        height: 300px;
        padding-top: 100px;
        display: flex;
        align-items: center;
        color: white;

        .grid {
            width: 100%;
        }
    }

    &-item {
        & > div {
            font-size: 28px;
            font-weight: bold;
        }
    }
}
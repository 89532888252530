@use '../settings/' as susankya;
:root {
    @each $color, $shades in susankya.$colors {
        @each $shade, $value in $shades {
            /* #{$color}--#{$shade} */
            --clr-#{ $color }--#{ $shade }: #{ $value };
        }
    }

    @each $font, $types in susankya.$fonts {
        @each $type, $value in $types {
            /* #{$font}--#{$type} */
            --font-#{ $font }--#{ $type }: #{ $value };
        }
    }
}

:root {
    --cms-main-title: 45px;
    --cms-hero-text--size: 18px;
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    font-size: map-get(map-get(susankya.$fonts, size), base)*1px;
    font-size: var(--font-size--base)+'px';
}

body {
    @include susankya.getColor(background-color, primary, 200);
    @include susankya.getColor(color, greyish, source);
    @include susankya.fetchGlobalVars(font-family, $flag: typography, $varname: susankya.$fonts, $type: family, $output: tt-commons);
    font-size: susankya.rem(xs)+'rem';

    @include susankya.mq($flag: min, $device: tablet, $width1: md) {
        font-size: susankya.rem(xs)+'rem';
    }
}
.cms-main-title {
    font-size: var(--cms-main-title);
}

.cms-hero-banner {
    height: 500px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(10, 59, 108, 0.5);
        z-index: 1;
    }
    .cms-container {
        position: relative;
        z-index: 2;
    }
    .sub-text {
        font-size: var(--cms-hero-text--size);
    }

    & + * {
        position: relative;
        z-index: 2;
    }
}
:root {
  /* primary--source */
  --clr-primary--source: #0a3b6c;
  /* secondary--source */
  --clr-secondary--source: #fea60c;
  /* whitish--source */
  --clr-whitish--source: #ffffff;
  /* blackish--source */
  --clr-blackish--source: #000000;
  /* greyish--source */
  --clr-greyish--source: #707070;
  /* family--tt-commons */
  --font-family--tt-commons: TT Commons, sans-serif;
  /* family--sans-serif */
  --font-family--sans-serif: sans-serif;
  /* family--serif */
  --font-family--serif: serif;
  /* size--xs */
  --font-size--xs: 15;
  /* size--sm */
  --font-size--sm: 16;
  /* size--md */
  --font-size--md: 17;
  /* size--lg */
  --font-size--lg: 19;
  /* size--xl */
  --font-size--xl: 30;
  /* size--xxl */
  --font-size--xxl: 40;
  /* size--base */
  --font-size--base: 16;
  /* weight--light */
  --font-weight--light: 300;
  /* weight--regular */
  --font-weight--regular: 400;
  /* weight--medium */
  --font-weight--medium: 500;
  /* weight--semi-bold */
  --font-weight--semi-bold: 600;
  /* weight--bold */
  --font-weight--bold: 700;
  /* style--italic */
  --font-style--italic: italic;
  /* style--oblique */
  --font-style--oblique: oblique;
  /* style--normal */
  --font-style--normal: normal;
  /* style--initial */
  --font-style--initial: initial;
  /* style--inherit */
  --font-style--inherit: inherit;
  /* variant--normal */
  --font-variant--normal: normal;
  /* variant--small-caps */
  --font-variant--small-caps: small-caps;
  /* variant--initial */
  --font-variant--initial: initial;
  /* variant--inherit */
  --font-variant--inherit: inherit;
  /* stretch--u-con */
  --font-stretch--u-con: ultra-condensed;
  /* stretch--e-con */
  --font-stretch--e-con: extra-condensed;
  /* stretch--s-con */
  --font-stretch--s-con: semi-condensed;
  /* stretch--con */
  --font-stretch--con: condensed;
  /* stretch--u-exp */
  --font-stretch--u-exp: ultra-expanded;
  /* stretch--e-exp */
  --font-stretch--e-exp: extra-expanded;
  /* stretch--s-exp */
  --font-stretch--s-exp: semi-expanded;
  /* stretch--exp */
  --font-stretch--exp: expanded;
  /* stretch--normal */
  --font-stretch--normal: normal;
  /* stretch--initial */
  --font-stretch--initial: initial;
  /* stretch--inherit */
  --font-stretch--inherit: inherit;
}

:root {
  --cms-main-title: 45px;
  --cms-hero-text--size: 18px;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-size: var(--font-size--base)px;
}

body {
  background-color: var(--clr-primary--200);
  color: #707070;
  color: var(--clr-greyish--source);
  font-family: TT Commons, sans-serif;
  font-family: var(--font-family--tt-commons);
  font-size: 0.9375rem;
}
@media only screen and (min-width: 1024px) {
  body {
    font-size: 0.9375rem;
  }
}

.cms-btn {
  padding: 12px 25px;
  background-color: #ffffff;
  background-color: var(--clr-whitish--source);
  color: #000000;
  color: var(--clr-blackish--source);
  display: inline-block;
  border-radius: 0px;
}
.cms-btn--secondary {
  background-color: #fea60c;
  background-color: var(--clr-secondary--source);
  color: #ffffff;
  color: var(--clr-whitish--source);
}
.cms-btn--rounded {
  border-radius: 4px;
}

* + .cms-btn {
  margin-top: 10px;
}

.cms-card--category {
  max-width: 75%;
  margin-inline: auto;
}
.cms-card--category .cms-cat-link {
  text-align: center;
}
.cms-card--category .cms-cat-link img {
  margin-inline: auto;
  width: 60px;
}
.cms-card--icon-grid .cms-card-title {
  font-size: 18px;
  font-weight: 700;
}
.cms-card--blog {
  position: relative;
}
.cms-card--blog article {
  position: relative;
  padding: 20px 0;
}
.cms-card--blog article .cms-btn {
  position: absolute;
  top: -30px;
  right: 15px;
}
.cms-card--blog .cms-card-title {
  font-size: 18px;
  font-weight: 700;
  color: #0a3b6c;
  color: var(--clr-primary--source);
}

.cms-header .cms-brand-logo {
  max-width: 150px;
}

.cms-nav-toggle {
  width: 30px;
  height: 30px;
  position: relative;
}
.cms-nav-toggle__bar {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 3px;
  background-color: #fea60c;
  background-color: var(--clr-secondary--source);
}
.cms-nav-toggle__bar:first-child {
  top: 0;
}
.cms-nav-toggle__bar:nth-child(2) {
  top: 8px;
}
.cms-nav-toggle__bar:nth-child(3) {
  top: 16px;
}

@media only screen and (min-width: 920px) {
  .cms-navbar .cms-navbar-nav li + li {
    margin-left: 12px;
  }
}
@media only screen and (min-width: 1024px) {
  .cms-navbar .cms-navbar-nav li + li {
    margin-left: 16px;
  }
}
@media only screen and (min-width: 1100px) {
  .cms-navbar .cms-navbar-nav li + li {
    margin-left: 25px;
  }
}
@media only screen and (min-width: 1200px) {
  .cms-navbar .cms-navbar-nav li + li {
    margin-left: 30px;
  }
}

.cms-main-title {
  font-size: var(--cms-main-title);
}

.cms-hero-banner {
  height: 500px;
  position: relative;
}
.cms-hero-banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 59, 108, 0.5);
  z-index: 1;
}
.cms-hero-banner .cms-container {
  position: relative;
  z-index: 2;
}
.cms-hero-banner .sub-text {
  font-size: var(--cms-hero-text--size);
}
.cms-hero-banner + * {
  position: relative;
  z-index: 2;
}

.cms-section-title {
  font-size: 30px;
  font-weight: 700;
}

.cms-section {
  padding: 50px 0;
}

.cms-search-category {
  position: relative;
}
.cms-search-category .cms-container, .cms-search-category .cms-card--category {
  padding: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
}
.cms-search-category .cms-card--category {
  width: 100%;
}
.cms-search-category .cms-container {
  padding-top: 100px;
}

.cms-counter {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
}
.cms-counter .cms-container {
  height: 300px;
  padding-top: 100px;
  display: flex;
  align-items: center;
  color: white;
}
.cms-counter .cms-container .grid {
  width: 100%;
}
.cms-counter-item > div {
  font-size: 28px;
  font-weight: bold;
}

.cms-container {
  max-width: 1140px;
  width: 100%;
}

.cms-text-primary--source {
  color: #0a3b6c;
  color: var(--clr-primary--source);
}

.cms-text-secondary--source {
  color: #fea60c;
  color: var(--clr-secondary--source);
}

.cms-text-whitish--source {
  color: #ffffff;
  color: var(--clr-whitish--source);
}

.cms-text-blackish--source {
  color: #000000;
  color: var(--clr-blackish--source);
}

.cms-text-greyish--source {
  color: #707070;
  color: var(--clr-greyish--source);
}
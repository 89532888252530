
@use './colors' as color;
@use './typography' as typo;
@use './breakpoint' as bp;

@mixin getColor($prop, $chooseColor, $output) {
    #{$prop}: map-get(map-get(color.$colors, $chooseColor), $output);
    #{$prop}: var(--clr-#{$chooseColor}--#{$output});
}

@mixin fetchGlobalVars($prop, $flag, $varname, $type, $output, $imp:false) {
    @if($flag == color) {
        @if($imp == true) {
            #{$prop}: map-get(map-get($varname, $type), $output) !important;
            #{$prop}: var(--clr-#{$type}--#{$output}) !important;
        } @else {
            #{$prop}: map-get(map-get($varname, $type), $output);
            #{$prop}: var(--clr-#{$type}--#{$output});
        }
    }

    @if($flag == typography) {
        @if($imp == true) {
            #{$prop}: map-get(map-get($varname, $type), $output) !important;
            #{$prop}: var(--font-#{$type}--#{$output}) !important;
        } @else {
            #{$prop}: map-get(map-get($varname, $type), $output);
            #{$prop}: var(--font-#{$type}--#{$output});
        }
    }
}

@function rem($varName) {
    @return #{map-get(map-get(typo.$fonts, size), $varName)/map-get(map-get(typo.$fonts, size), base)};
}

@mixin prefix-class($prefix, $className) {
    .#{$prefix}-#{$className} {
        @content;
    }
}

@mixin mq($flag:min, $device, $width1, $width2:null) {
    @if ($flag == min) {
        @media only screen and (min-width: map-get(map-get(bp.$breakpoints, #{$device}), #{$width1})) {
            @content;
        }
    }
    
    @if($flag == max) {
        @media only screen and (max-width: map-get(map-get(bp.$breakpoints, #{$device}), #{$width1})) {
            @content;
        }
    }
    
    @if($flag == min-max) {
        @media only screen and (min-width: map-get(map-get(bp.$breakpoints, #{$device}), #{$width1})) and (max-width: map-get(map-get(bp.$breakpoints, #{$device}), #{$width2})) {
            @content;
        }
    }
}
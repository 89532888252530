@use '../settings/' as cms;

.cms-card {
    &--category {
        max-width: 75%;
        margin-inline: auto;

        .cms-cat-link {
            text-align: center;

            img {
                margin-inline: auto;
                width: 60px;
            }
        }
    }

    &--icon-grid {
        .cms-card-title {
            font-size: 18px;
            font-weight: 700;
        }
    }

    &--blog {
        position: relative;

        article {
            position: relative;
            padding: 20px 0;

            .cms-btn {
                position: absolute;
                top: -30px;
                right: 15px;
            }
        }

        .cms-card-title {
            font-size: 18px;
            font-weight: 700;
            @include cms.getColor(color, primary, source);
        }

    }
}
@use '../settings/' as cms;

.cms-header {
    .cms-brand-logo {
        max-width: 150px;
    }
}

.cms-nav-toggle {
    width: 30px;
    height: 30px;
    position: relative;

    &__bar {
        position: absolute;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 3px;
        @include cms.getColor(background-color, secondary, source);

        &:first-child {
            top: 0;
        }

        &:nth-child(2) {
            top: 8px;
        }

        &:nth-child(3) {
            top: 16px;
        }
    }
}

.cms-navbar {
    .cms-navbar-nav {
        @include cms.mq(min, tablet, sm) {
            li + li {
                margin-left: 12px;
            }
        }
        @include cms.mq(min, tablet, md) {
            li + li {
                margin-left: 16px;
            }
        }
        @include cms.mq(min, tablet, lg) {
            li + li {
                margin-left: 25px;
            }
        }
        @include cms.mq(min, tablet, xl) {
            li + li {
                margin-left: 30px;
            }
        }
    }
}
@use '../settings/' as cms;
.cms-btn {
    padding: 12px 25px;
    @include cms.getColor(background-color, whitish, source);
    @include cms.getColor(color, blackish, source);
    display: inline-block;
    border-radius: 0px;
    
    &--secondary {
        @include cms.getColor(background-color, secondary, source);
        @include cms.getColor(color, whitish, source);
    }

    &--rounded {
        border-radius: 4px;
    }
}

* + .cms-btn {
    margin-top: 10px;
}